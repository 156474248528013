export var directoryMap = new Map();
export var UUID = Date.now().toString();

export function getChannelName(
  isGroup: boolean,
  sbChannelName: string,
  peerUserIds: string[],
  currentUserID: string
) {
  var name: string;
  if (!isGroup && (sbChannelName === "" || sbChannelName === "Group Channel")) {
    var temp: string[] = [];
    peerUserIds.forEach(peerUserId => {
      peerUserId !== currentUserID &&
        directoryMap.get(peerUserId).name !== undefined &&
        temp.push(directoryMap.get(peerUserId).name);
    });
    name = temp.join();
  } else {
    name = sbChannelName;
  }
  return name;
}
export function getLastMessage(
  channel: {
    lastMessage: {
      messageType: string;
      _sender: { userId: any; nickname: any };
      message: string;
      createdAt: any;
    } | null;
  },
  currentUserID: string,
  isGroup: boolean
) {
  var lastMessage;
  if (isGroup) {
    lastMessage = {
      message:
        channel.lastMessage !== null &&
        (channel.lastMessage.messageType === "user"
          ? (channel.lastMessage._sender.userId === currentUserID
              ? "you"
              : channel.lastMessage._sender.nickname) +
            " : " +
            channel.lastMessage.message
          : (channel.lastMessage._sender.userId === currentUserID
              ? "you"
              : channel.lastMessage._sender.nickname) +
            " : sent you an Attachment"),
      time: channel.lastMessage !== null && channel.lastMessage.createdAt
    };
  } else
    lastMessage = {
      message:
        channel.lastMessage !== null &&
        (channel.lastMessage.messageType === "user"
          ? (channel.lastMessage._sender.userId === currentUserID
              ? "you"
              : channel.lastMessage._sender.nickname) +
            " : " +
            channel.lastMessage.message
          : (channel.lastMessage._sender.userId === currentUserID
              ? "you"
              : channel.lastMessage._sender.nickname) +
            " : sent you an Attachment"),
      time: channel.lastMessage !== null && channel.lastMessage.createdAt
    };
  return lastMessage;
}
