import * as firebase from "firebase/app";
import "firebase/auth";
import "firebase/firestore";
import "firebase/database";
import "firebase/functions";

const axios = require("axios").default;

const firebaseConfigDev = {
    apiKey: "AIzaSyBUigpq8v-3_xNl0IuwJ-Kv2_R1wF_sa7s",
    authDomain: "fir-saml.firebaseapp.com",
    databaseURL: "https://fir-saml.firebaseio.com",
    projectId: "fir-saml",
    storageBucket: "fir-saml.appspot.com",
    messagingSenderId: "880843751706",
    appId: "1:880843751706:web:37fc68fee341d107ee6ca3"
};

const firebaseConfig = {
    apiKey: "AIzaSyCW1l_9KmgksqATOgIU7W-cElSL3JI4F4A",
    authDomain: "connectqa-25449.firebaseapp.com",
    databaseURL: "https://connectqa-25449.firebaseio.com",
    projectId: "connectqa-25449",
    storageBucket: "connectqa-25449.appspot.com",
    messagingSenderId: "118200802386",
    appId: "1:118200802386:web:f846965d50fe6be522d38b"
};

firebase.initializeApp(firebaseConfig);

export function signOut() {
    /* return firebase.auth().signOut(); */
    window.open('https://qa-intivahealth.cs34.force.com/secur/singlelogout.jsp', '_self');
}

export function signIn() {
    var provider = new firebase.auth.SAMLAuthProvider('saml.qaidpprovider');
    console.log('signIn');
    firebase.auth().signInWithRedirect(provider);
}

export var firebaseApp = firebase.auth();

firebase.auth().onAuthStateChanged(function (user) {

    // user is undefined if no user signed in
    if (user !== null && user !== undefined) {
        user.getIdTokenResult().then((result) => { console.log(result.token) });
    }
});

export async function getDirectory() {
    return new Promise(async (resolve, reject) => {
        try {
            const access_token = await firebase.auth().currentUser.getIdToken();

            const directoryResponse = await axios.get('https://us-central1-connectqa-25449.cloudfunctions.net/getDirectory',
                {
                    headers: {
                        Authorization: "Bearer " + access_token
                    }
                }
            );
            resolve(directoryResponse.data);
        } catch (error) {
            console.error("Error while any of the await might have failed", error);
            reject("Error while any of the await might have failed");
        }
    });
}
